<template>
  <div class="phone-number">
    <div class="progress-bar">
      <img src="@/assets/images/jdt.png" alt="" />
      <div
        class="circle"
        style="left: -29px"
        :class="index >= 0 ? 'state' : ''"
      >
        <i></i>
        <p class="num">1</p>
        <p class="text">验证身份</p>
      </div>
      <div
        class="bar"
        style="left: 36px"
        :style="index >= 1 ? 'background: #00cc88' : ''"
      ></div>
      <div
        class="circle"
        style="left: 150px"
        :class="index >= 1 ? 'state' : ''"
      >
        <i></i>
        <p class="num">2</p>
        <p class="text">绑定新手机号</p>
      </div>
      <div
        class="bar"
        style="left: 216px"
        :style="index >= 2 ? 'background: #00cc88' : ''"
      ></div>
      <div
        class="circle"
        style="left: 329px"
        :class="index >= 2 ? 'state' : ''"
      >
        <i></i>
        <p class="num">3</p>
        <p class="text">修改成功</p>
      </div>
    </div>
    <div class="message">
      <div class="number" v-if="index != 2">
        <div v-if="index === 0">
          <p>当前手机号：</p>
          <i>{{ user.userEntity.telNum }}</i>
        </div>
        <div v-else-if="index === 1">
          <p>新手机号码：</p>
          <!-- <el-select v-model="qh">
            <el-option
              :label="list.name + list.number"
              value=""
              v-for="(list, index) in areaCode"
              :key="index"
            ></el-option>
          </el-select> -->
          <!-- <input type="text" /> -->
          <el-form
            :model="Form"
            :rules="rules"
            class="demo-ruleForm"
            ref="Form"
          >
            <el-form-item prop="newNum">
              <el-input v-model.number="Form.newNum"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="verification-code" v-if="index != 2">
        <p>验证码：</p>
        <input type="text" v-model="code" @keyup.enter="Submit('Form')" />
        <svg-icon icon-class="ic-x" v-show="code" @click="code = ''"></svg-icon>
        <button @click="getCode" v-if="countdown === 120">获取验证码</button>
        <div v-else>{{ countdown }}s后重新获取</div>
      </div>
      <div class="tips" :style="index === 2 ? 'height: auto;' : ''">
        <div v-if="countdown != 120">
          <i class="el-icon-warning-outline"></i>
          验证码已发送至您的手机，120秒内有效
        </div>
        <img src="@/assets/images/prosperity.png" alt="" v-if="index > 1" />
        <div v-if="index > 1">手机号修改成功</div>
      </div>
      <button
        class="submit"
        :style="code ? 'background:var(--subjectColor);cursor: pointer;' : ''"
        @click="Submit('Form')"
        v-if="index != 2"
      >
        提交验证
      </button>
      <a v-if="index === 2" class="accomplish" @click="finish">完 成</a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "phone-number",
  computed: {
    ...mapState("personalUser", ["userInfo"]),
  },
  data() {
    return {
      index: 0,
      code: "", //验证码input
      countdown: 120, //验证码倒计时
      Form: {
        newNum: "",
      },
      rules: {
        newNum: [
          {
            required: true,
            message: "请输入新手机号码",
            trigger: "blur",
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (/^(?:(?:\+|00)86)?1\d{10}$/.test(value) == false) {
                callback(new Error("手机号格式不正确"));
              } else {
                callback();
              }
            },
            message: "手机号格式不正确",
            // trigger: "blur",
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value == this.user.userEntity.telNum) {
                callback(new Error("新手机号和原手机号相同"));
              } else {
                callback();
              }
              // console.log(rule, value, this.user.userEntity.telNum);
            },
            message: "新手机号和原手机号相同",
            // trigger: "blur",
          },
        ],
      },

      newNum: "",
      areaCode: [
        {
          number: "010",
          name: "北京市",
        },
      ],
      qh: "",
    };
  },
  watch: {
    countdown: {
      handler: function (val, oldVal) {
        // console.log(val);
        if (val < 1) {
          this.countdown = 120;
          clearInterval(this.t);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log(this.user);
  },
  destroyed() {
    clearInterval(this.t);
  },
  methods: {
    //获取验证码
    async getCode() {
      this.countdown--;
      this.t = setInterval(() => {
        this.countdown--;
      }, 1000);

      const data = await this.$API.personalUser.sendCAPTCHA({
        mobile:
          this.index === 0 ? this.user.userEntity.telNum : this.Form.newNum,
      });

      // console.log(data);
    },
    Submit(formName) {
      if (this.code) {
        if (this.index === 1) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.Vcode();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        } else {
          this.Vcode();
        }
      }
    },
    async Vcode() {
      const data = await this.$API.personalUser.verificationCAPTCHA({
        mobile:
          this.index === 0 ? this.user.userEntity.telNum : this.Form.newNum,
        captcha: this.code,
      });
      if (data.data.data.result) {
        if (this.index === 1) {
          this.updateUser().catch((err) => {
            console.log(err);
            this.$message.error("更改失败");
          });
        } else {
          this.index++;
          this.code = "";
          clearInterval(this.t);
          this.countdown = 120;
        }
      } else {
        this.$message.error("验证码错误");
      }
    },
    async updateUser() {
      //修改用户信息
      const data = await this.$API.personalUser.updateUser({
        object: {
          id: this.user.id,
          userDetailEntity: {
            // frontId: "",
            id: this.user.userEntity.id,
            // idNumber: "",
            profilePicture: this.user.userDetailEntity.profilePicture, //头像
            // reverseId: "",
            // userId: "",
          },
          userEntity: {
            actualName: "",
            id: this.user.userEntity.id,
            name: this.user.userEntity.name, //名称
            password: "",
            telNum: this.Form.newNum,
            username: this.Form.newNum,
          },
        },
      });
      if (data.data.code === "FAILED") {
        this.$message.error("该账号已注册");
      } else {
        this.index++;
        this.code = "";
        clearInterval(this.t);
        this.countdown = 120;
        sessionStorage.removeItem("userInfo");
        this.$store.dispatch("personalUser/getUserInfo");
      }
    },
    finish() {
      sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      this.index = 0;
      this.$router.push({
        path: "/PersonalCenter/UserData/DataPage",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-number {
  padding: 40px 300px;
  // display: flex;
  .progress-bar {
    position: relative;
    margin-bottom: 66px;
    .circle {
      position: absolute;
      width: 100px;
      top: 4px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .num {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        // background: #00cc88;
        position: relative;
      }
      .text {
        margin-top: 10px;
        font-size: 14px;
        color: #999999;
      }
      i {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        position: absolute;
        top: -4px;
        left: 29px;
        // background: #d8f7ed;
        z-index: 0;
      }
    }
    .bar {
      width: 147px;
      height: 6px;
      // background: #00cc88;
      position: absolute;
      top: 18px;
      z-index: 10;
    }
    .state {
      .num {
        background: #00cc88;
      }
      .text {
        color: #00cc88;
      }
      i {
        background: #d8f7ed;
      }
    }
  }
  .message {
    font-size: 14px;
    color: #333333;
    .number {
      line-height: 20px;
      margin-bottom: 20px;
      div {
        display: flex;
        align-items: center;
        .el-form-item {
          margin-bottom: 0 !important;
        }
        ::v-deep .el-input__inner {
          width: 186px;
          border-radius: 0;
          padding: 0 10px;
        }
        // ::v-deep input {
        //   width: 186px !important;
        //   // width: 204px;
        //   height: 40px;
        //   background: #ffffff;
        //   border: 1px solid #dfdfdf;
        //   // border-left: none;
        //   font-size: 14px;
        //   color: #333333;
        //   padding: 0 12px;
        // }
        // ::v-deep input:focus {
        //   border: 1px solid var(--subjectColor);
        //   outline: none;
        // }
        ::v-deep .el-select {
          width: 102px;
          input {
            padding-left: 8px;
            padding-right: 18px;
            border-radius: 0;
          }
          .el-input__suffix {
            right: 0;
          }
        }
      }
    }
    .verification-code {
      line-height: 40px;
      position: relative;
      display: flex;
      p {
        width: 84px;
        text-align: end;
      }
      input {
        width: 186px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        padding: 0 10px;
        margin-right: 12px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      input:focus,
      select:focus {
        border: 1px solid var(--subjectColor) !important;
        outline: none;
      }
      input:hover {
        border-color: #c0c4cc;
      }
      .svg-icon {
        cursor: pointer;
        position: absolute;
        top: 14px;
        left: 250px;
        font-size: 12px;
      }
      button {
        width: 110px;
        height: 40px;
        background: #fff;
        border: 1px solid var(--subjectColor);
        opacity: 1;
        border-radius: 2px;
        color: var(--subjectColor);
        cursor: pointer;
      }
      div {
        width: 110px;
        height: 40px;
        background: #f6f6f6;
        border-radius: 2px;
        font-size: 12px;
        color: #999999;
        text-align: center;
        cursor: default;
      }
    }
    .tips {
      margin: 6px 0 18px 0;
      text-align: center;
      color: #999;
      height: 19px;
      img {
        margin-bottom: 8px;
      }
    }
    .submit {
      width: 400px;
      height: 42px;
      background: #dddddd;
      opacity: 1;
      border: none;
      color: #ffffff;
      letter-spacing: 1px;
      font-size: 14px;
    }
    .accomplish {
      width: 340px;
      height: 42px;
      display: inline-block;
      background: var(--subjectColor);
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      border: none;
      cursor: pointer;
      margin-top: 12px;
      position: relative;
      left: 50%;
      margin-left: -170px;
      text-align: center;
      line-height: 42px;
    }
  }
}
</style>